// src/analytics.js
import ReactGA from 'react-ga4';

export const initializeAnalytics = () => {
  ReactGA.initialize('YOUR_GA4_MEASUREMENT_ID'); // Replace with your GA4 measurement ID
};

export const logPageView = (page) => {
  ReactGA.send({ hitType: 'pageview', page });
};

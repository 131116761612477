import './App.css';
import React, { useEffect } from 'react';
import { initializeAnalytics, logPageView } from './analytics';
import Header from './components/header/Header';
import Home from './components/home/Home';
import Vision from './components/vision/Vision';
import Services from './components/services/Services';
import Why from './components/why/Why';
import Contact from './components/contact/Contact';
import Technologies from './components/technologies/Technologies';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// import Sitemap from './components/sitemap/Sitemap';

function App() {
  useEffect(() => {
    initializeAnalytics(); // Initialize Google Analytics
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/gpacalculator" element={<GpaCalculator />} />
        <Route path="/" element={<HomeWithSEO />} />
        {/* <Route path="/sitemap.xml" element={<Sitemap />} /> */}
      </Routes>
    </Router>
  );
}

function HomeWithSEO() {
  usePageAnalytics('/'); // Track Home page
  return (
    <>
      <Helmet>
        <title>Code Helio Technologies</title>
      </Helmet>
      <Header />
      <main className="main">
        <Home />
        <Vision />
        <Services />
        <Why />
        <Technologies />
        <Contact />
      </main>
    </>
  );
}

function GpaCalculator() {
  usePageAnalytics('/gpacalculator'); // Track GPA Calculator page
  return (
    <>
      <Helmet>
        <title>GPA Calculator</title>
        <meta name="keywords" content="bitgpacalculator, gpa calculator, bit, ucsc, fit, uoc, degree, University, Colombo, Lanka, Computing, education, IT, Information, Technology, Diploma, Certificate, courses, science, bit gpa calculator" />
      </Helmet>
      <div className="external-page">
        <iframe
          title="Gpa Calculator"
          src="https://bit-gpacalculator.web.app/main/gpacalculator"
          width="100%"
          height="100%"
          frameBorder="0"
        ></iframe>
      </div>
    </>
  );
}

const usePageAnalytics = (page) => {
  const location = useLocation();

  useEffect(() => {
    logPageView(page || location.pathname); // Track page views
  }, [location]);
};

export default App;
